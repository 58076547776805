<template>
  <div id="user-profile">
    <b-card
      class="profile-header mb-2"
      :img-src="headerData.coverImg"
      img-top
      alt="cover photo"
      body-class="p-0"
    >
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img
              :src="headerData.avatar"
              rounded
              fluid
              alt="profile photo"
            />
          </div>
          <!-- profile title -->
          <div class="profile-title ml-3">
            <h2 class="text-white">
              {{ row.companyName }}
            </h2>
            <p class="text-white">
              <i class="el-icon-arrow-right"></i> {{ row.accountCode }}
              <i class="el-icon-location"></i> {{ row.companyAddress }}
            </p>
          </div>
          <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->

      <!-- profile navbar -->
      <div class="profile-header-nav">
        <b-navbar
          toggleable="md"
          type="light"
        >
          <!-- collapse -->
          <b-collapse id="nav-text-collapse" is-nav>
            <b-tabs pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0" v-model="tabIndex">
              <b-tab :active="activeTab.startsWith('#overview')">
                <template #title>
                  <i class="el-icon-star-on"></i> Overview
                </template>
                <div class="tab-content">
                  <profile-overview ref="profileOverview" v-if="row.id" :activeTab="activeTab" :profile="row"/>
                </div>
              </b-tab>
              <b-tab :active="activeTab.startsWith('#profile')">
                <template #title>
                  <i class="el-icon-office-building"></i> Profile
                </template>
                <div class="tab-content">
                  <profile-profile ref="profileProfile" v-if="row.id" :activeTab="activeTab" :profile="row"/>
                </div>
              </b-tab>
              <b-tab :active="activeTab.startsWith('#chargeCode')">
                <template #title>
                  <i class="el-icon-s-finance"></i> Charge Code
                </template>
                <div class="tab-content">
                  <profile-charge-code ref="profileChargeCode" v-if="row.id" :activeTab="activeTab" :profile="row" :chargeCodes="chargeCodes"/>
                </div>
              </b-tab>
              <b-tab :active="activeTab.startsWith('#document')">
                <template #title>
                  <i class="el-icon-s-claim"></i> Document
                </template>
                <div class="tab-content">
                  <profile-document ref="profileDocument" v-if="row.id" :activeTab="activeTab" :profile="row"/>
                </div>
              </b-tab>
              <b-tab :active="activeTab.startsWith('#invoice')">
                <template #title>
                  <i class="el-icon-document-checked"></i> Invoice
                </template>
                <div class="tab-content">
                  <profile-invoice ref="profileInvoice" v-if="row.id" :activeTab="activeTab" :profile="row" type="INVOICE"/>
                </div>
              </b-tab>
              <b-tab :active="activeTab.startsWith('#credit-note')">
                <template #title>
                  <i class="el-icon-document-add"></i> Credit Note
                </template>
                <div class="tab-content">
                  <profile-invoice ref="profileInvoice" v-if="row.id" :activeTab="activeTab" :profile="row" type="CREDIT_NOTE"/>
                </div>
              </b-tab>
              <b-tab :active="activeTab.startsWith('#accrual')">
                <template #title>
                  <i class="el-icon-document"></i> Accrual
                </template>
                <div class="tab-content">
                  <profile-accrual ref="profileAccrual" v-if="row.id" :activeTab="activeTab" :profile="row"/>
                </div>
              </b-tab>
              <b-tab :active="activeTab.startsWith('#auditLog')">
                <template #title>
                  <i class="el-icon-connection"></i> Audit Log
                </template>
                <div class="tab-content">
                  <profile-audit-log ref="profileAuditLog" v-if="row.id" :activeTab="activeTab" :profile="row"/>
                </div>
              </b-tab>

              <!-- edit buttons -->
              <template #tabs-end>
                <b-button variant="secondary" class="ml-auto" @click="editProfile()" v-if="getErpCanWriteGlobal()">
                  <feather-icon icon="EditIcon" class="d-block d-md-none"/>
                  <span class="font-weight-bold d-none d-md-block"><i class="el-icon-edit"></i> Edit Detail</span>
                </b-button>

                <b-button variant="primary" class="ml-1" @click="showStatusChangeDialog">
                  <span class="font-weight-bold" v-if="row.status === 'PENDING'"><i class="el-icon-alarm-clock"></i> Pending</span>
                  <span class="font-weight-bold" v-if="row.status === 'VERIFIED'"><i class="el-icon-check"></i> Verified</span>
                  <span class="font-weight-bold" v-if="row.status === 'DOCUMENTED'"><i class="el-icon-document-checked"></i> Documented</span>
                  <span class="font-weight-bold" v-if="row.status === 'SUSPENDED'"><i class="el-icon-warning"></i> Suspended</span>
                  <span class="font-weight-bold" v-if="row.status === 'BLACKLISTED'"><i class="el-icon-delete"></i> Blacklisted</span>
                  <span class="font-weight-bold" v-if="row.status === 'INACTIVE'"><i class="el-icon-close"></i> Inactive</span>
                  <span class="font-weight-bold" v-if="row.status === 'DELETED'"><i class="el-icon-delete"></i> Deleted</span>
                </b-button>
              </template>
              <!-- edit buttons -->
            </b-tabs>
          </b-collapse>
          <!--/ collapse -->
        </b-navbar>
      </div>
      <!--/ profile navbar -->
    </b-card>

    <el-dialog
      title="Status Change"
      width="50%"
      :visible.sync="isShowStatusChange">
      <el-form>
        <el-form-item :label="$t('Status')">
          <el-select size="mini" v-model="statusChange.status" ref="input" :disabled="!getErpCanWriteGlobal()">
            <el-option v-for="(k, v) in PROFILE_STATUSES" :key="v" :label="k" :value="v"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Remark')">
          <el-input type="textarea" size="mini" v-model="statusChange.remark" ref="input" :disabled="!getErpCanWriteGlobal()"/>
        </el-form-item>
        <el-form-item>
          <b-button variant="primary" @click="updateStatus()" :disabled="!getErpCanWriteGlobal()">Update</b-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { BCard, BCol, BCollapse, BImg, BNavbar, BRow, BTabs } from "bootstrap-vue";
import ProfileOverview from "@/views/erp/company-profile/ProfileOverview.vue";
import ProfileProfile from "@/views/erp/company-profile/ProfileProfile.vue";
import ProfileChargeCode from "@/views/erp/company-profile/ProfileChargeCode.vue";
import ProfileDocument from "@/views/erp/company-profile/ProfileDocument.vue";
import ProfileInvoice from "@/views/erp/company-profile/ProfileInvoice.vue";
import ProfileAuditLog from "@/views/erp/company-profile/ProfileAuditLog.vue";
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@/configs/apis";
import ProfileAccrual from "@/views/erp/company-profile/ProfileAccrual.vue";
import { getErpCanWriteGlobal } from "@/utils/account-localstorage";
import axios from "axios";
import { PROFILE_STATUSES } from "@/views/erp/utils/system";
import Prompt from "@/views/erp/mixins/Prompt";

/* eslint-disable global-require */
export default {
  mixins: [Prompt],
  computed: {
    PROFILE_STATUSES() {
      return PROFILE_STATUSES
    }
  },
  components: {
    ProfileAccrual,
    ProfileAuditLog,
    ProfileInvoice,
    ProfileDocument,
    ProfileChargeCode,
    ProfileProfile,
    BTabs,
    BImg,
    BCard,
    BNavbar,
    BCollapse,
    BRow,
    BCol,
    ProfileOverview
  },
  data() {
    return {
      tabIndex: 2,
      headerData: {
        coverImg: '/default_cover.png',
        avatar: '/default_avatar.png',
      },
      row: {},
      invoices: [],
      chargeCodes: [],
      chargeCodeMap: {},
      activeTab: '#overview',
      statusChange: {},
      isShowStatusChange: false,
    }
  },
  mounted() {
    this.loadChargeCodes();

    // Load existing profile
    if (this.$route.params.id !== undefined) {
      const id = this.$route.params.id;
      this.loadProfile(id);
    }

    if (location.hash) {
      this.activeTab = location.hash;
    }
  },
  methods: {
    getErpCanWriteGlobal,
    async loadProfile(id) {
      axios.get(apis.erpOrganizationProfile + '/' + id)
        .then(response => {
          console.log( response.data.data);
          this.row = response.data.data;
        })
        .catch(e => {
          alert(e.message);
          history.go(-1);
        });
    },
    async loadChargeCodes() {
      axios.get(apis.erpChargeCodes)
        .then(response => {
          this.chargeCodes = response.data.data.data;
        });
    },
    editProfile() {
      this.$router.push({
        name: 'erp-organisation-profile-edit',
        params: { id: this.row.id }
      })
    },

    updateStatus() {
      this.isLoading = true;
      axios.patch(apis.erpOrganizationProfile + '/' + this.statusChange.id, this.statusChange)
        .then(response => {
          this.promptInfo("Status Updated");
        })
        .finally(() => {
          this.isLoading = false;
          this.isShowStatusChange = false;
          this.loadProfile(this.statusChange.id);
        })
    },

    showStatusChangeDialog() {
      this.isShowStatusChange = true;
      this.statusChange = {
        id: this.row.id,
        status: this.row.status,
        remark: ''
      }
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>

<style scoped>
.profile-header img {
    min-height: 120px;
    max-height: 120px;
}

.nav-item i {
    padding-right: 10px;
    font-size: 20px;
}

.tab-content {
    position: relative;
    margin-top: 20px;
    min-height: 60vh;
}
</style>
