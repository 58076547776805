<template>
  <div class="p-2">
    <b-row v-loading="isLoading">
      <b-col>
        <h3 class="mb-2">Company Profile Settings</h3>
        <el-form :model="profile" ref="mainForm">
          <el-form-item :label="$t('Currency Profile')">
            <el-select size="mini" v-model="profile.currencyProfileId" ref="input" @change="updateCurrencyProfile()" :disabled="!getErpCanWriteGlobal()">
              <el-option v-for="item in currencyProfiles" :key="item.id" :label="item.name + (item.deleteTime ? ' (Deleted)' : '')" :value="item.id" :disabled="item.deleteTime !== null" v-if="item.deleteTime === null || item.id === profile.currencyProfileId"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('Invoice Email Template')">
            <el-select size="mini" v-model="profile.invoiceEmailTemplateId" ref="input" @change="updateEmailTemplate()" :disabled="!getErpCanWriteGlobal()">
              <el-option v-for="item in emailTemplates" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-button size="mini" class="ml-1" @click="openModalForTest()" v-if="getErpCanWriteGlobal()"><i class="el-icon-s-promotion"></i> Test</el-button>
          </el-form-item>

          <el-form-item :label="$t('Invoice Day (Day of Month)')">
            <el-input-number size="mini" :min="1" :max="28" controls-position="right" v-model="profile.invoiceDay" ref="input" @change="updateInvoiceDay()" :disabled="!getErpCanWriteGlobal()"/>
          </el-form-item>
        </el-form>
      </b-col>
      <b-col>
        <h3>Last 10 Invoices</h3>
        <!-- Invoice -->
        <table class="w-100">
          <tr>
            <th class="py-1">Invoice Number</th>
            <th class="py-1">Amount</th>
            <th class="py-1">Status</th>
            <th class="py-1">Actions</th>
          </tr>
          <tr v-for="invoice in invoices" @click="showInvoice(invoice)">
            <td class="py-1">{{ invoice.invoiceNumber }}</td>
            <td class="py-1">{{ invoice.totalAmount }} {{ invoice.totalAmountCurrency }}</td>
            <td class="py-1">
              <InvoiceStatusTag :item="invoice"/>
            </td>
            <td class="py-1">
              <el-link @click.stop="downloadInvoice(invoice)" v-if="invoice.invoiceNumber !== null">
                <i class="el-icon-paperclip"></i>
                Download PDF
              </el-link>

              <el-link @click="showInvoice(invoice)" class="px-1">
                <i class="el-icon-view"></i>
                View
              </el-link>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <EmailTestModal ref="emailTestView"/>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import apis from "@/configs/apis";
import EmailTestModal from "@/views/erp/components/EmailTestModal.vue";
import { EventBus } from "@/views/erp/utils/event-bus";
import InvoiceStatusTag from "@/views/erp/components/InvoiceStatusTag.vue";
import { getErpCanWriteGlobal } from "@/utils/account-localstorage";
import File from "@/views/erp/mixins/File";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";
import { PROFILE_STATUSES } from "@/views/erp/utils/system";

export default {
  computed: {
    PROFILE_STATUSES() {
      return PROFILE_STATUSES
    }
  },
  mixins: [File, Prompt],
  mounted() {
    this.loadInvoices();
    this.loadEmailTemplates();
    this.loadCurrencyProfiles();

    EventBus.$on('invoice-updated', () => {
      this.loadInvoices();
    });
  },
  data() {
    return {
      invoices: [],
      emailTemplates: [],
      currencyProfiles: [],
      isLoading: false,
    }
  },
  methods: {
    getErpCanWriteGlobal,
    openModalForTest() {
      this.$refs.emailTestView.show(this.profile, this.emailTemplates.filter(a => {return a.id === this.profile.invoiceEmailTemplateId})[0]);
    },
    showInvoice(item) {
      this.$router.push({
        name: 'erp-invoice-view',
        params: { company_id: item.organizationProfileId, id: item.id }
      })
    },
    async loadInvoices() {
      const response = await axios.get(apis.erpInvoices, {params: {pageSize: 10, accountCode: this.profile.accountCode}});
      this.invoices = response.data.data.data;
    },
    loadEmailTemplates() {
      this.isLoading = true;

      axios.get(apis.erpEmailTemplates)
        .then(response => {
          this.emailTemplates = response.data.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    loadCurrencyProfiles() {
      this.isLoading = true;

      axios.get(apis.erpCurrencyProfiles, {params: {isShowDeleted: 'true'}})
        .then(response => {
          this.currencyProfiles = response.data.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    updateCurrencyProfile() {
      this.isLoading = true;
      axios.patch(apis.erpOrganizationProfile + '/' + this.profile.id, {currencyProfileId: this.profile.currencyProfileId})
        .then(response => {
          this.promptInfo("Currency Profile Updated");
        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    updateEmailTemplate() {
      this.isLoading = true;
      axios.patch(apis.erpOrganizationProfile + '/' + this.profile.id, {invoiceEmailTemplateId: this.profile.invoiceEmailTemplateId})
        .then(response => {
          this.promptInfo("Email Template Updated");
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    updateInvoiceDay() {
      this.isLoading = true;
      axios.patch(apis.erpOrganizationProfile + '/' + this.profile.id, {invoiceDay: this.profile.invoiceDay})
        .then(response => {
          this.promptInfo("Invoice Day Updated");
        })
        .finally(() => {
          this.isLoading = false;
        })
    }
  },
  components: {
    InvoiceStatusTag,
    EmailTestModal,
    BCard,
    BCardText,
  },
  props: {
    profile: Object,
  },
}
</script>

